import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.css']
})
export class ItemEventComponent implements OnChanges {

  @Input() arrayList: any = [];
  @Input() codigo_pdv: any = "";
  @Input() estado_id: any = "";
  @Input() eventosLista: any = [];
  eventosAux_: any = [];

  constructor(private router: Router, private authService: AuthService) {
    this.authService.eventosAux.subscribe((response) => {
      if (response) {
        this.eventosAux_ = response;
      }
    });
    this.authService.location.subscribe((response) => {
      if (response) {
        var selected = JSON.parse(response);
        if (selected[0].id == 0) {
          var aux = this.eventosLista.filter(x => x.estado == "BRASIL");
          aux.map((item) => {
            item.eventos = this.eventosAux_
          });
          this.arrayList = aux.filter(x => x.estado == "BRASIL");
        } else {
          var vef = this.eventosLista.filter(x => x.estado == selected[0].nome);
          if (vef.length > 0) {
            const fromIndex = this.eventosLista.indexOf(vef[0]);
            const element = this.eventosLista.splice(fromIndex, 1)[0]
            this.eventosLista.unshift(element);
            var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
            aux.map((item) => {
              item.eventos = [];
            });
            this.arrayList = this.eventosLista;

          } else {
            this.arrayList = [];
          }
        }
      }
    })
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  async init() {

    // if (this.eventosLista.length > 0) {
    //   var novoEvento = {
    //     "codigo": "EVT_PORAO_ROCK_2024",
    //     "nome": "FESTIVAL PORÃO DO ROCK",
    //     "data_inicio": "2024-05-23T18:00:00.0000000",
    //     "data_fim": "2024-05-24T18:00:00.0000000",
    //     "endereco": {
    //       "cep": "71630100",
    //       "logradouro": "Quadra SHIS QL 10",
    //       "bairro": "Setor de Habitações Individuais Sul",
    //       "cidade_id": 804,
    //       "cidade": "BRASÍLIA",
    //       "estado_id": 7,
    //       "estado": "DISTRITO FEDERAL"
    //     },
    //     "status": "ATIVO",
    //     "observacao": "<h1 style=\"margin-left: 0px;\"><b>FESTIVAL PORÃO DO ROCK</b></h1><p style=\"margin-left: 0px;\">ESTACIONAMENTO DA ARENA BRB - Brasília - DF<br></p><p style=\"margin-left: 0px;\">23 e 24 de maio de 2024 - A partir das 18h<br><br><br></p><p style=\"margin-left: 0px;\"><b>Uma experiência incrível para os amantes de rock!</b></p><p style=\"margin-left: 0px;\">Adquira já seus ingressos pelos canais oficiais e garanta momentos inesquecíveis ao som de grandes bandas.</p><p style=\"margin-left: 0px;\"><b>Política de Cancelamento para Pedidos</b></p><p style=\"margin-left: 0px;\">É possível solicitar o cancelamento de pedidos até 7 dias após a compra, desde que a solicitação seja encaminhada com um mínimo de 48 horas antes do início do evento agendado.</p><p style=\"margin-left: 0px;\"><b>Transferência de Participantes</b></p><p style=\"margin-left: 0px;\">A possibilidade de transferir o participante de um ingresso está disponível em uma única ocasião. Essa opção estará acessível até 24 horas antes do início do evento.</p><p style=\"margin-left: 0px;\">Importante: Aquisição de Ingressos Exclusivamente por Canais Oficiais</p><p style=\"margin-left: 0px;\">Salientamos que não assumimos responsabilidade por ingressos obtidos por meios alheios aos nossos canais oficiais de venda. Em situações que levantem suspeitas de fraude, há a possibilidade de cancelamento do ingresso, podendo ocorrer sem aviso prévio. Sua compreensão é fundamental para garantir a integridade do evento.</p>",
    //     "imagem": "banner1.png",
    //     "imagem_link": "../assets/images/banner1.png",
    //     "imagem_banner_interno_link": "",
    //     "imagem_banner_interno": "",
    //     "imagem_banner_evento_link": "../assets/images/banner1.png",
    //     "imagem_banner_evento": "banner1.png",
    //     "imagem_sobre": "",
    //     "imagem_sobre_link": "",
    //     "estilo_descricao": "ROCK",
    //     "empresa_cpf_cnpj": "00.000.000/0000-00",
    //     "menor_valor": 0,
    //     "local": "ESTACIONAMENTO DA ARENA BRB",
    //     "imagem_banner_principal_link": "",
    //     "exibir_banner_principal": false,
    //     "ordem_exibicao": 1,
    //     "tipo_taxa_servico": "PORCENTAGEM",
    //     "taxa_servico": 12,
    //     "oculto": false,
    //     "tipo_cupom": "OPCIONAL",
    //     "mais_informacao": "",
    //     "pdv_imprimir_ingresso": true,
    //     "pdv_informar_cliente": true,
    //     "exibir_lote_esgotado": true,
    //     "local_evento": true
    //   }

    //   const distritoFederal = this.eventosLista.find((estado) => estado.estado === "DISTRITO FEDERAL");

    //   if (distritoFederal) {
    //     // Verifica se o evento já existe pelo código
    //     const eventoExiste = distritoFederal.eventos.some(evento => evento.codigo === novoEvento.codigo);

    //     if (!eventoExiste) {
    //       // Adiciona o novo evento à lista
    //       distritoFederal.eventos.unshift(novoEvento);
    //       console.log("Evento adicionado com sucesso!");
    //     } else {
    //       console.log("Evento já está na lista.");
    //     }
    //   } else {
    //     console.log("Estado 'DISTRITO FEDERAL' não encontrado.");
    //   }
    // }

    var location_ = await localStorage.getItem('@DIngress:location');
    if (location_) {
      var selected = JSON.parse(location_);
      if (selected[0].id == 0) {
        var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
        aux.map((item) => {
          item.eventos = this.eventosAux_
        });
        this.arrayList = aux.filter(x => x.estado == "BRASIL");
      } else {

        var vef = this.eventosLista.filter(x => x.estado == selected[0].nome);
        if (vef.length > 0) {
          const fromIndex = this.eventosLista.indexOf(vef[0]);
          const element = this.eventosLista.splice(fromIndex, 1)[0]
          this.eventosLista.unshift(element);
          var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
          aux.map((item) => {
            item.eventos = [];
          });
          this.arrayList = this.eventosLista;

        } else {
          this.arrayList = [];
        }
      }
    } else {
      var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
      aux.map((item) => {
        item.eventos = this.eventosAux_;
      });
      this.arrayList = this.eventosLista.filter(x => x.estado == "BRASIL");
    }
  }

  // filterArray(arrayList_) {
  //   var dados = [];
  //   dados = this.arrayList.filter(x => x.eventos.length > 0);    
  //   var selected = this.authService.location.getValue();

  //   return dados
  // }

  goToEvent(d) {
    if (d.codigo == 'EVT_PORAO_ROCK_2024') {
      window.open(`https://poraodorock.digitalingressos.com.br/porao-do-rock-brasilia`, '_blank');
    } else
      if (this.codigo_pdv) {
        this.router.navigate([`/event/${d.codigo}/${this.codigo_pdv}`]);
      } else {
        this.router.navigate(['/event/' + d.codigo]);
      }
  }

  formaterCurrency(value) {
    if (value) {
      if (value.includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value

  }

  formatDateDayMonth(value) {
    if (value) {
      return moment(value).format("DD/MM")
    }

    return "--/--"
  }

  formatDateDay(value) {
    if (value) {
      return moment(value).format("DD")
    }

    return "--/--"
  }

  formatDateMonth(value) {
    if (value) {
      moment.locale('pt-br');
      return moment(value).format("MMM")
    }

    return "--/--"
  }


  goToPorao() {
    window.open(`https://poraodorock.digitalingressos.com.br/porao-do-rock-brasilia`, '_blank');
  }
}
