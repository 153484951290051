import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ModalEditUserComponent } from 'src/app/components/modal-edit-user/modal-edit-user.component';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var jQuery: any;
declare var window: any;
import * as moment from "moment";
import { ModalCardAddressComponent } from 'src/app/components/modal-card-address/modal-card-address.component';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { TagManagerService } from 'src/app/service/tagService.service';
declare var mp: any;
declare var $: any;
declare var fbq: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PaymentComponent implements OnInit, OnDestroy {
  user: any = {};
  cartoes: any = [];
  checkOutData: any = {};
  ingressosUnficados: any = [];
  global_: any;
  cartao: any = {
    numero: ''
  };
  srcCard: any = "";
  loading: any = false;
  objPay: any = {};
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  timer;
  dateExpiration: any = 0;
  interval: any;
  jsonMP: any = '';

  cpfInvalid: any = false;

  codigo_pdv: any = "";
  tipoPagamento: any = 'CARTAO_CREDITO';
  retornoPix: any = null;
  pixPayment;
  aceitTerms: any = false;
  time: any = "";

  enderecoCartao: any = {};

  utilizarEnderecoCadastro: any = true;

  listsEstate: any = [];
  listsCity: any = [];
  visibilityEvent;
  imagePath: any;
  card: any = {};

  cardForm: any;
  loadingRequest: any = false;

  constructor(
    private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private viacep: NgxViacepService,
    private _sanitizer: DomSanitizer,
    private tagManagerService: TagManagerService,
  ) {
    this.global_ = global;
    var currentUser = localStorage.getItem('@DIngress:currentUser');
    var checkOut = JSON.parse(localStorage.getItem('@DIngress:checkOut'));
    this.user = currentUser;
    this.checkOutData = checkOut;
    this.ingressosUnficados = JSON.parse(checkOut.bilhetes_simplificados);
    this.getTimeServidor();

    if (!window.location.hash) {
      window.location = window.location + '#pay';
      window.location.reload();
    }
    let that = this;
    var callTime = function () {
      if (document.hidden) {
        clearInterval(that.timer);
      }
      else {
        if (window.location.pathname.includes('/payment')) {
          that.getTimeServidor();
        } else {
          document.removeEventListener('visibilitychange', callTime);
        }
      }
    }
    document.removeEventListener('visibilitychange', callTime)
    this.visibilityEvent = document.addEventListener('visibilitychange', callTime);

  }

  ngOnInit() {
    const codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (codigo_pdv) {
      this.codigo_pdv = codigo_pdv;
    }
    window.addEventListener('message', this.handleMessage);
    this.authService.setPdvCodigo(this.codigo_pdv)
    this.getUserCliente();
    this.initMpForm();
    // setTimeout(() => {
    //   document.getElementById('form-checkout__installments').addEventListener('change', function() {
    //     const selectElement = this as HTMLSelectElement;
    //     if (selectElement.value === '') {
    //         selectElement.classList.add('error-border');
    //     } else {
    //         selectElement.classList.remove('error-border');
    //     }
    // });
    // }, 1000);

    setTimeout(() => {
      this.initFb();
      if (this.checkOutData.evento.tag_google_manager) {
        this.initGtag();
      }
    }, 2000);
  }

  initGtag() {
    console.log("initGtm");
    const dynamicTagManagerId = this.checkOutData.evento.tag_google_manager; // Substitua pelo valor dinâmico
    this.tagManagerService.setTagManagerId(dynamicTagManagerId);
    this.tagManagerService.addGtmScript();
  }

  handleMessage = (e: MessageEvent) => {
    let that = this;
    if (e.data.status === 'COMPLETE') {
      setTimeout(() => {
        this.alerts.notifyInfo({
          body: `Pagamento solicitado`,
          title: 'Sucesso'
        });
        try {
          fbq('track', 'Purchase', {
            content_name: that.checkOutData.evento.nome,
            content_category: that.checkOutData.evento.estilo_descricao,
            content_ids: [that.checkOutData.evento.codigo],
            content_type: 'product',
            value: that.checkOutData.preco,
            currency: 'BRL'
          });
        } catch (error) {

        }
      }, 1000);
      if (that.codigo_pdv) {
        that.router.navigate([`/tickets/${that.codigo_pdv}`]);
      } else {
        that.router.navigate(['/tickets']);
      }
    }
  }


  ngOnDestroy() {
    setTimeout(() => {
      this.tagManagerService.removeGtmScript();
    }, 1000);
    if (this.pixPayment) {
      clearInterval(this.pixPayment);
    }
  }

  initFb() {
    try {
      var that = this;
      fbq('track', 'AddToCart',
        {
          content_name: that.checkOutData.evento.nome,
          content_category: that.checkOutData.evento.estilo_descricao,
          content_ids: [that.checkOutData.evento.codigo],
          content_type: 'product',
          value: that.checkOutData.preco,
          currency: 'BRL'
        }
      );
    } catch (error) {

    }
  }

  getTimeServidor() {
    this.apiService.GetV2('DataHora').then((res) => {
      this.time = res;
      this.startTimer();
    }).catch((err) => {
      console.log(err)
    });
  }

  startTimer = () => {
    var now: any = new Date(this.time);
    var end: any = new Date(this.checkOutData.expirado_em.split(".")[0]);
    var distance = end - now;
    if (distance < 0) {
      setTimeout(() => {
        this.alerts.alertError({
          title: 'Pedido com tempo expirado',
          text: `Ops.. Seu pedido anterior já expirou, tente realizar uma nova compra`,
        }).then((result) => {
          if (this.codigo_pdv) {
            this.router.navigate([`/home/${this.codigo_pdv}`]);
          } else {
            this.router.navigate(['/home']);
          }
        })
      }, 1000);
    } else {
      var that = this;
      this.timer = setInterval(() => {
        if (window.location.pathname.includes('/payment')) {
          var now: any = new Date(this.time);
          var end: any = new Date(that.checkOutData.expirado_em.split(".")[0]);
          var distance = end - now;
          if (distance < 0) {
            clearInterval(that.timer);
            setTimeout(() => {
              that.alerts.notifyWarning({
                body: `Seu pedido expirou`,
                title: 'Alerta'
              });
            }, 1000);
            that.dateExpiration = 0;
            if (that.codigo_pdv) {
              that.router.navigate([`/home/${this.codigo_pdv}`]);
            } else {
              that.router.navigate(['/home']);
            }
            return;
          }
          var days = Math.floor(distance / that._day);
          var hours = Math.floor((distance % that._day) / that._hour);
          var minutes = Math.floor((distance % that._hour) / that._minute);
          var seconds = Math.floor((distance % that._minute) / that._second);
          that.dateExpiration = `${minutes}:${seconds.toString().length > 1 ? seconds : `0${seconds}`}`;
          this.time = moment(this.time).add(1, 'seconds');
        } else {
          clearInterval(that.timer);
        }
      }, 1000);
    }
  }

  getUserCliente() {
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];
      this.enderecoCartao = { ...this.user.endereco };
      this.getStates();
    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {

        this.alerts.notifyError({
          body: `Erro ${err.error}`,
          title: 'Erro'
        });
      }
    });
  }

  getCreditCards() {
    this.apiService.Get('Clientes/CartoesMP').then((res) => {
      this.cartoes = res;
    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar obter cartões`,
        title: 'Erro'
      });
    });
  }




  doChallenge(payment) {
    let that = this;
    $(".section-credit-card").css("display", "none");
    $("#container-section").css("display", "inline-flex");
    try {
      const {
        status, creq, external_resource_url
      } = payment;
      if (status === "EM_PROCESSO_DESAFIO") {
        var iframe: any = document.createElement("iframe");
        var containerSection: any = document.getElementById("container-section");
        iframe.name = "myframe";
        iframe.id = "myframe";
        containerSection.appendChild(iframe);

        var idocument = iframe.contentWindow.document;

        var myform = idocument.createElement("form");
        myform.name = "myform";
        myform.setAttribute("target", "myframe");
        myform.setAttribute("method", "post");
        myform.setAttribute("action", external_resource_url);

        var hiddenField = idocument.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "creq");
        hiddenField.setAttribute("value", creq);
        myform.appendChild(hiddenField);
        iframe.appendChild(myform);

        myform.submit();
      }
    } catch (error) {
      that.alerts.alertError({
        title: 'Erro ao validar',
        text: `Erro ao validar código, tente novamente mais tarde`,
      }).then((result) => {
        this.loading = false;
        document.location.reload();
      })
    }
  }



  payService(obj, tipo = '') {
    this.apiService.Post('Pagamentos', obj).then((res) => {
      this.loading = false;
      let that = this;
      if (res['status'] == 'EM_PROCESSO_DESAFIO') {
        that.doChallenge(res)
      } else {
        try {
          fbq('track', 'initiateCheckout');
        } catch (error) {
        }
        if (tipo == 'PIX') {
          this.retornoPix = res;
          if (this.retornoPix.pix.qr_code_base64) {
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
              + this.retornoPix.pix.qr_code_base64);
          }
          this.pixPayment = setInterval(() => {
            that.checkPayment(res['codigo']);
          }, 10000);
        } else if (tipo == 'PIC_PAY') {
          this.retornoPix = res;
          if (this.retornoPix.carteira_digital.length > 0) {
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
              + this.retornoPix.carteira_digital[0].qr_code64);
          }
          this.pixPayment = setInterval(() => {
            that.checkPaymentPicPay(res['codigo']);
          }, 10000);
        } else {
          setTimeout(() => {
            this.alerts.notifyInfo({
              body: `Pagamento solicitado ${res['codigo']}`,
              title: 'Sucesso'
            });
            try {
              fbq('track', 'Purchase', {
                content_name: that.checkOutData.evento.nome,
                content_category: that.checkOutData.evento.estilo_descricao,
                content_ids: [that.checkOutData.evento.codigo],
                content_type: 'product',
                value: that.checkOutData.preco,
                currency: 'BRL'
              });
            } catch (error) {

            }
          }, 1000);
          if (that.codigo_pdv) {
            that.router.navigate([`/tickets/${this.codigo_pdv}`]);
          } else {
            that.router.navigate(['/tickets']);
          }
        }
      }
    }).catch((err) => {
      this.loadingRequest = false;
      if (err.status == 524) {
        this.alerts.alertWarning({
          title: 'Atenção',
          text: `Não conseguimos processar a sua compra, favor tentar novamente`,
        }).then((result) => {
          this.loading = false;
          document.location.reload();
        })
      } else if (err.status == 403) {
        this.alerts.alertWarning({
          title: 'Pedido com tempo expirado',
          text: `Ops.. Seu pedido anterior já expirou, tente realizar uma nova compra`,
        }).then((result) => {
          if (this.codigo_pdv) {
            this.router.navigate([`/home/${this.codigo_pdv}`]);
          } else {
            this.router.navigate(['/home']);
          }
        })
      } else {
        this.loading = false;
        this.alerts.alertError({
          title: 'Atenção',
          text: `codigo: ${JSON.stringify(err.status)} - ${JSON.stringify(err.error)}`,
        }).then((result) => {
          this.loading = false;
          document.location.reload();
        })
      }
    });
  }

  setInstallments(status, response) {
    if (status == 200) {

      let installments = jQuery('installments')[0];
      response[0].payer_costs.forEach(payerCost => {
        let opt = document.createElement('option');
        opt.text = payerCost.recommended_message;
        opt.value = payerCost.installments;
        installments.appendChild(opt);
      });

    } else {
    }
  }

  showAlert(msg) {
    this.alerts.alertError({
      title: 'Atenção',
      text: `${msg}`,
    })
  }

  showRemaining() {
    var now: any = new Date();
    var end: any = new Date(this.checkOutData.expirado_em);
    var distance = end - now;
    if (distance < 0) {
      clearInterval(this.timer);
      this.dateExpiration = 0;
      if (this.codigo_pdv) {
        this.router.navigate([`/home/${this.codigo_pdv}`]);
      } else {
        this.router.navigate(['/home']);
      }
      this.alerts.notifyError({
        body: `Seu pedido expirou`,
        title: 'Erro'
      });
      return;
    }
    var days = Math.floor(distance / this._day);
    var hours = Math.floor((distance % this._day) / this._hour);
    var minutes = Math.floor((distance % this._hour) / this._minute);
    var seconds = Math.floor((distance % this._minute) / this._second);
    this.dateExpiration = `${minutes}:${seconds}`;
  }

  doPay(tipo: any) {

    if (this.aceitTerms == false) {
      return;
    }

    if (this.loading) {
      return;
    }

    if (tipo == 'CARTAO_CREDITO') {

      if (!this.cpfInvalid) {
        this.showAlert('Cpf inválido, favor preencher com um cpf válido');
        this.loading = false;
        return;
      }
      this.loading = true;
      if (jQuery('#form-checkout__cardNumber').val() == "" || jQuery('#form-checkout__cardNumber').val() == null) {
        this.showAlert('Favor preencher o campo número do cartão');
        this.loading = false;
        return;
      }

      if (jQuery('#form-checkout__expirationDate').val() == "" || jQuery('#form-checkout__expirationDate').val() == null) {
        this.showAlert('Favor preencher o campo ano de validade');
        this.loading = false;
        return;
      }

      if (jQuery('#form-checkout__securityCode').val() == "" || jQuery('#form-checkout__securityCode').val() == null) {
        this.showAlert('Favor preencher o campo cvv');
        this.loading = false;
        return;
      }

      if (jQuery('#form-checkout__cardholderName').val() == "" || jQuery('#form-checkout__cardholderName').val() == null) {
        this.showAlert('Favor preencher o campo nome do cartão');
        this.loading = false;
        return;
      }

      if (jQuery('#form-checkout__cardholderEmail').val() == "" || jQuery('#form-checkout__cardholderEmail').val() == null) {
        this.showAlert('Favor preencher o campo Email');
        this.loading = false;
        return;
      }

      if (jQuery('#form-checkout__identificationNumber').val() == "" || jQuery('#form-checkout__identificationNumber').val() == null) {
        this.showAlert('Favor preencher o CPF');
        this.loading = false;
        return;
      }

      this.cardForm.submit();

    } else if (tipo == 'PIX') {
      this.iniciarPagamentoPIX()
    } else if (tipo == 'PIC_PAY') {
      this.iniciarPagamentoPicPay()
    }
  }

  showAlertMercado() {
    this.alerts.alertError({
      title: 'Atenção',
      text: `Favor verificar dados`,
    }).then((result) => {
      this.loading = false;
      document.location.reload();
    })
  }

  iniciarPagamentoPIX() {
    this.loadingRequest = true;
    this.objPay.tipo_pagamento = 'PIX';
    this.objPay.checkout = this.checkOutData;
    this.loading = true;
    this.payService(this.objPay, 'PIX');
  }

  iniciarPagamentoPicPay() {
    this.loadingRequest = true;
    this.objPay.tipo_pagamento = 'PIC_PAY';
    this.objPay.checkout = this.checkOutData;
    this.loading = true;
    this.payService(this.objPay, 'PIC_PAY');
  }

  finalizarPagamento(dados) {
    // clearInterval(this.interval);
    this.jsonMP = dados;
    this.objPay.tipo_pagamento = 'CARTAO_CREDITO';
    this.objPay.checkout = this.checkOutData;
    this.objPay.checkout.parcelas = jQuery('#installments').val() == "" ? 1 : parseInt(jQuery('#installments').val());
    this.objPay.cartao_credito = {
      titular_nome: this.cartao.titular_nome,
      titular_cpf: this.cartao.titular_cpf,
      titular_data_nascimento: `${this.cartao.data.split("/")[2]}-${this.cartao.data.split("/")[1]}-${this.cartao.data.split("/")[0]}T00:00:00`,
      bandeira: this.cartao.paymentMethodId.toLocaleUpperCase(),
      cvv: parseInt(jQuery('#securityCode').val()),
      token_cartao: this.jsonMP.filter(x => x.name == 'token')[0].value,
      issuer_id: '',
      salvo: false,
      endereco: this.enderecoCartao
    }
    this.payService(this.objPay);
  }

  editUserData() {
    const dialogRef = this.dialog.open(ModalEditUserComponent, {
      width: 'auto',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserCliente();
    });
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;

    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return false;
  }

  checkFreeTickets() {
    var value = this.ingressosUnficados.filter(x => x.valor_unitario > 0);
    if (value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  doPayFree() {
    this.loading = true;
    this.objPay.tipo_pagamento = 'GRATUIDADE';
    this.objPay.checkout = this.checkOutData;
    this.objPay.cartao_credito = {};
    this.payService(this.objPay);
  }

  copyToClipboard(text: any) {
    var $temp = jQuery("<input>");
    jQuery("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
    this.alerts.notifyInfo({
      body: `Pix copiado`,
      title: 'Copiado'
    });
  }

  checkPayment(pagamento_codigo) {
    this.apiService.Get(`Pagamentos/${pagamento_codigo}/Pix`).then((res) => {
      this.loading = false;
      let that = this;
      if (res['status'] == 'CONCLUIDA') {
        clearInterval(this.pixPayment);
        setTimeout(() => {
          this.alerts.notifyInfo({
            body: `Pagamento solicitado ${res['codigo']}`,
            title: 'Sucesso'
          });
          try {
            fbq('track', 'Purchase', {
              value: that.checkOutData.preco,
              currency: 'BRL',
              content_ids: [that.checkOutData.evento.codigo],
              content_type: 'product',
            });
          } catch (error) {

          }
        }, 1000);
        if (that.codigo_pdv) {
          that.router.navigate([`/tickets/${this.codigo_pdv}`]);
        } else {
          that.router.navigate(['/tickets']);
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.alertError({
        title: 'Atenção',
        text: `${err.error}`,
      }).then((result) => {

        this.loading = false;
        document.location.reload();
      })
    });
  }

  checkPaymentPicPay(pagamento_codigo) {
    this.apiService.Get(`Pagamentos/${pagamento_codigo}/CarteiraDigital`).then((res) => {
      this.loading = false;
      let that = this;

      if (res[0].status != 'Created') {
        clearInterval(this.pixPayment);
        setTimeout(() => {
          this.alerts.notifyInfo({
            body: `Pagamento solicitado ${res['codigo']}`,
            title: 'Sucesso'
          });
          try {
            fbq('track', 'Purchase', {
              value: that.checkOutData.preco,
              currency: 'BRL',
              content_ids: [that.checkOutData.evento.codigo],
              content_type: 'product',
            });
          } catch (error) {

          }

        }, 1000);
        if (that.codigo_pdv) {
          that.router.navigate([`/tickets/${this.codigo_pdv}`]);
        } else {
          that.router.navigate(['/tickets']);
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.alertError({
        title: 'Atenção',
        text: `${err.error}`,
      }).then((result) => {
        this.loading = false;
        document.location.reload();
      })
    });
  }

  editAddressCard() {
    const dialogRef = this.dialog.open(ModalCardAddressComponent, {
      width: 'auto',
      data: this.enderecoCartao
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.enderecoCartao = result;
      }
    });
  }

  changeUseAddress(usarAddress) {
    if (usarAddress) {
      this.enderecoCartao = this.user.endereco;
    }
  }

  searchCep() {
    if (this.enderecoCartao.cep != null && this.enderecoCartao.cep != "" && this.enderecoCartao.cep.length == 8) {
      this.getCep(this.enderecoCartao.cep)
    }
  }

  selectState(estado_id) {
    if (estado_id != null && estado_id != 0) {
      this.getCidades(estado_id);
    } else {
      this.enderecoCartao.cidade_id = 0;
    }
  }

  getStates() {
    this.apiService.GetPublic('Estados').then((res) => {
      this.listsEstate = res;
      this.listsEstate.unshift({
        "nome": "Estado",
        id: 0
      });
      if (this.enderecoCartao.estado_id != null && this.enderecoCartao.estado_id != 0) {
        this.getCidades(this.enderecoCartao.estado_id);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCidades(id_estado, localidade = null) {
    this.apiService.GetPublic(`Estados/${id_estado}/Cidades`).then((res) => {
      this.listsCity = res;
      this.listsCity.unshift({
        "nome": "Cidade",
        id: 0
      });
      // this.enderecoCartao.cidade_id = 0;
      if (localidade != null) {
        let verfiCidade = this.listsCity.filter(x => x.nome == localidade.toUpperCase());
        if (verfiCidade.length > 0) {
          this.enderecoCartao.cidade_id = verfiCidade[0].id;
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCep(cep) {
    this.viacep
      .buscarPorCep(cep)
      .pipe(
        catchError((error: CEPError) => {

          return EMPTY;
        })
      )
      .subscribe((endereco: Endereco) => {
        this.enderecoCartao.logradouro = endereco.logradouro;
        this.enderecoCartao.bairro = endereco.bairro;
        if (endereco.uf) {
          let verfiEstado = this.listsEstate.filter(x => x.sigla == endereco.uf.toUpperCase());
          if (verfiEstado.length > 0) {
            this.enderecoCartao.estado_id = verfiEstado[0].id;
            this.getCidades(this.enderecoCartao.estado_id, endereco.localidade);
          }
        }
      });
  }

  initMpForm() {
    let that = this;
    setTimeout(() => {
      that.cardForm = mp.cardForm({
        amount: this.checkOutData.preco.toString(),
        autoMount: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número do cartão",
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de segurança",
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular do cartão",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emissor",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Parcelas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "CPF",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn("Form Mounted handling error: ", error);
          },
          onPaymentMethodsReceived: (error, paymentMethods) => {
            if (error) return console.warn('paymentMethods handling error: ', error)
            that.srcCard = paymentMethods[0].thumbnail;
          },
          onIssuersReceived: (error, issuers) => {
            if (error) return console.warn('issuers handling error: ', error)
          },
          onInstallmentsReceived: (error, installments) => {
            if (error) {
              console.warn('installments handling error: ', error)
              return
            }
          },
          onCardTokenReceived: (error, token) => {
            if (error) return console.warn('Token handling error: ', error)
          },
          onSubmit: event => {
            event.preventDefault();

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = that.cardForm.getCardFormData();

            that.loadingRequest = true;
            that.objPay.tipo_pagamento = 'CARTAO_CREDITO';
            that.objPay.checkout = that.checkOutData;
            that.objPay.checkout.parcelas = Number(installments);
            // that.objPay.checkout.parcelas = 1

            that.objPay.cartao_credito = {
              titular_nome: jQuery('#form-checkout__cardholderName').val(),
              titular_cpf: identificationNumber,
              titular_data_nascimento: `${that.card.data.split("/")[2]}-${that.card.data.split("/")[1]}-${that.card.data.split("/")[0]}T00:00:00`,
              bandeira: payment_method_id.toLocaleUpperCase(),
              cvv: parseInt(jQuery('#form-checkout__securityCode').val()),
              token_cartao: token,
              issuer_id: issuer_id,
              salvo: false,
              endereco: that.enderecoCartao
            }

            that.payService(that.objPay);

          },
          onFetching: (resource) => {
            console.log("Fetching resource: ", resource);
          }
        },
        onReady: () => {
          console.log("CardForm ready");
        }
      });
    }, 2000);
  }

  changeTipo(tipo: any) {
    let that = this;
    if (this.tipoPagamento != tipo) {
      this.tipoPagamento = tipo;
      if (tipo != 'CARTAO_CREDITO') {
        try {
          this.cardForm.unmount();
        } catch (error) {

        }
      } else {
        that.initMpForm();
        // var proprities = {
        //   amount: this.checkOutData.preco.toString(),
        //   autoMount: false,
        //   form: {
        //     id: "form-checkout",
        //     cardholderName: {
        //       id: "form-checkout__cardholderName",
        //       placeholder: "Titular (nome no cartão)",
        //     },
        //     cardholderEmail: {
        //       id: "form-checkout__cardholderEmail",
        //       placeholder: "E-mail",
        //     },
        //     cardNumber: {
        //       id: "form-checkout__cardNumber",
        //       placeholder: "Número do cartão",
        //     },
        //     cardExpirationMonth: {
        //       id: "form-checkout__cardExpirationMonth",
        //       placeholder: "Mês",
        //     },
        //     cardExpirationYear: {
        //       id: "form-checkout__cardExpirationYear",
        //       placeholder: "Ano",
        //     },
        //     securityCode: {
        //       id: "form-checkout__securityCode",
        //       placeholder: "CVV",
        //     },
        //     installments: {
        //       id: "form-checkout__installments",
        //       placeholder: "Parcelas",
        //     },
        //     identificationType: {
        //       id: "form-checkout__identificationType",
        //       placeholder: "Tipo de documento",
        //     },
        //     identificationNumber: {
        //       id: "form-checkout__identificationNumber",
        //       placeholder: "CPF do titular",
        //     },
        //     issuer: {
        //       id: "form-checkout__issuer",
        //       placeholder: "Banco emissor",
        //     },
        //   },
        //   callbacks: {
        //     onFormMounted: error => {
        //       if (error) return console.warn("Form Mounted handling error: ", error);
        //       console.log("Form mounted");
        //     },
        //     onPaymentMethodsReceived: (error, paymentMethods) => {
        //       if (error) return console.warn('paymentMethods handling error: ', error)
        //       that.srcCard = paymentMethods[0].thumbnail;
        //     },
        //     onFormUnmounted: error => {
        //       if (error) return console.warn('Form Unmounted handling error: ', error)
        //       console.log('Form unmounted')
        //     },

        //     onSubmit: event => {
        //       event.preventDefault();
        //       try {
        //         const {
        //           paymentMethodId: payment_method_id,
        //           issuerId: issuer_id,
        //           cardholderEmail: email,
        //           amount,
        //           token,
        //           installments,
        //           identificationNumber,
        //           identificationType,
        //         } = that.cardForm.getCardFormData();

        //         that.objPay.tipo_pagamento = 'CARTAO_CREDITO';
        //         that.objPay.checkout = that.checkOutData;
        //         that.objPay.checkout.parcelas = Number(installments);
        //         // that.objPay.checkout.parcelas = 1;
        //         that.objPay.cartao_credito = {
        //           titular_nome: jQuery('#form-checkout__cardholderName').val(),
        //           titular_cpf: identificationNumber,
        //           titular_data_nascimento: `${that.card.data.split("/")[2]}-${that.card.data.split("/")[1]}-${that.card.data.split("/")[0]}T00:00:00`,
        //           bandeira: payment_method_id.toLocaleUpperCase(),
        //           cvv: parseInt(jQuery('#form-checkout__securityCode').val()),
        //           token_cartao: token,
        //           issuer_id: issuer_id,
        //           salvo: false,
        //           endereco: that.enderecoCartao
        //         }

        //         that.payService(that.objPay);

        //       } catch (error) {
        //         that.alerts.alertWarning({
        //           title: 'Atenção',
        //           text: `Não conseguimos processar a sua compra, favor tentar novamente`,
        //         }).then((result) => {
        //           that.loading = false;
        //           document.location.reload();
        //         })
        //       }
        //     },
        //     onError: (error, event) => {
        //       console.log(event, error);
        //     },
        //     onReady: () => {
        //       console.log("CardForm ready");
        //     }
        //   },
        // };
        // setTimeout(() => {
        //   this.cardForm = mp.cardForm(proprities);
        //   this.cardForm.mount();
        // }, 2000)
      }
    }
  }

}
