import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoginCadastroComponent } from 'src/app/components/modal-login-cadastro/modal-login-cadastro.component';
import { ModalIngressoPosseComponent } from 'src/app/components/modal-ingresso-posse/modal-ingresso-posse.component';
import { Meta, Title } from "@angular/platform-browser";
import { split } from 'ts-node';
import { TagManagerService } from 'src/app/service/tagService.service';
import { ModalIngressoSeatsComponent } from 'src/app/components/modal-ingresso-seats/modal-ingresso-seats.component';
declare var Swiper: any;
declare var $: any;
declare var fbq: any;
declare var seatsio: any;


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EventComponent implements OnInit, OnDestroy {

  panelAbout: any = true;
  event: any = {};
  cupomActived: any = true;
  loadingCupom: any = false;
  cupom: any = null;
  cupomCodigo: any = "";
  @ViewChild('sectionAbout', { read: ElementRef, static: false }) sectionAbout: ElementRef;
  @ViewChild('sectioLocalization', { read: ElementRef, static: false }) sectioLocalization: ElementRef;
  @ViewChild('sectionInfo', { read: ElementRef, static: false }) sectionInfo: ElementRef;
  @ViewChild('sectionHorario', { read: ElementRef, static: false }) sectionHorario: ElementRef;
  @ViewChild('sectionHorarioMob', { read: ElementRef, static: false }) sectionHorarioMob: ElementRef;

  lat: number = 0;
  lng: number = 0;
  zoom: number = 15;
  private geoCoder;
  listSession: any = [];
  indexSession: any = 0;
  areaEvent: any = [];
  ingressAreaSelected: any = [];
  valorTotal: any = 0;
  valorTotalAux: any = 0;
  valorTotalSemCupom: any = 0;

  user: any = {};
  loadingCheckout: any = false;
  loadingArea: any = false;
  sessoes: any = [];
  codigo_pdv: any = "";
  swiperDay: any = null;
  swiperDay2: any = null;
  exibirMapa: any = false;
  horarioSelecionado: any = 0;
  testeSwipe: any = [
    {
      "chave": "2024-05-05T00:00:00.0000000",
      "sessoes": [
        {
          "nome": "ÚNICA",
          "inicio": "2024-05-05T10:00:00.0000000",
          "fim": "2024-05-05T23:00:00.0000000",
          "codigo": "SES_BC5E496C14C74164",
          "status": "Ativo"
        }
      ]
    },
    {
      "chave": "2024-05-15T00:00:00.0000000",
      "sessoes": [
        {
          "nome": "ÚNICA",
          "inicio": "2024-05-05T10:00:00.0000000",
          "fim": "2024-05-05T23:00:00.0000000",
          "codigo": "SES_BC5E496C14C74164",
          "status": "Ativo"
        }
      ]
    },
  ];


  //seats io
  seatingChart: any;
  usaMapa: any = false;
  selectedSeatsArray: any = [];
  sessaoSelecionadaMapa: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private title: Title,
    private meta: Meta,
    private tagManagerService: TagManagerService,
  ) {
    this.ngZone.runOutsideAngular(() => {
      const codigo = this.route.snapshot.paramMap.get('codigo');
      if (codigo) {
        this.getEvent(codigo);
        this.getEventSession(codigo);
      }
    });
  }

  ngOnInit() {
    this.initScreen();
    console.log("init")
    setTimeout(() => {
      this.initFb();
    }, 2000);
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.tagManagerService.removeGtmScript();
    }, 1000);
  }

  initGtag() {
    console.log("initGtm");
    const dynamicTagManagerId = this.event.tag_google_manager; // Substitua pelo valor dinâmico
    this.tagManagerService.setTagManagerId(dynamicTagManagerId);
    this.tagManagerService.addGtmScript();
  }

  initScreen() {
    this.authService.saveCurrentCheckout(null);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);

    this.getUserCliente();
    const codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (codigo_pdv) {
      this.codigo_pdv = codigo_pdv;
    }
    this.authService.setPdvCodigo(this.codigo_pdv)
    let that = this;

    setTimeout(() => {
      const swiperEl: any = document.querySelector('swiper-container#swiperContainer');
      const swiperElMb: any = document.querySelector('swiper-container#swiperContainerMob');
      const params = {
        injectStyles: [`
        .swiper-pagination-bullet {
          display:none;
        }
        .swiper-button-next {
          position: absolute;
          top: 50%;
          right: 87px;
          width: 45px;
          height: 45px;
          transform: translateY(-50%);
          margin-top: 0px;
          z-index: 999;
          color: grey;
      }
      .swiper-button-prev {
          margin-top: 0px;
          position: absolute;
          top: 50%;
          left: 70px;
          width: 45px;
          height: 45px;
          transform: translateY(-50%);
          z-index: 999;
          color: grey;
      }
      .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
    }

    .swiper-slide-active .swiper-slide .line{
      width: 30px;
      height: 3px;
      border-radius: 200px;
      margin: 10px auto;
      background: #6054b0;
    }

        `],
        effect: "coverflow",
        grabCursor: true,
        slideToClickedSlide: false,
        centeredSlides: true,
        slidesPerView: 4,
        coverflowEffect: {
          rotate: 0,
          stretch: -10,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        },
        navigation: {
          // nextEl: '.swiper-button-prev-unique',
          // prevEl: '.swiper-button-next-unique'
        },
        pagination: {
          // el: ".swiper-pagination",
        },
        on: {
          slideChange: function (el) {
            const activeIndex = el.activeIndex;
            that.atualizarSessaoHora(activeIndex);
          }
        }
      }
      Object.assign(swiperEl, params);
      Object.assign(swiperElMb, params);
      swiperEl.initialize();
      swiperElMb.initialize();
      $(".swiper-button-prev").html('<i class="fa fa-chevron-right"></i>');
      $(".swiper-button-next").html('<i class="fa fa-chevron-left"></i>');
    }, 1000);
  }


  initFb() {
    try {
      var that = this;
      fbq('track', 'PageView', {
        value: that.valorTotal,
        currency: 'BRL',
        content_ids: [that.event.codigo],
        content_type: 'product'
      });
    } catch (error) {
      console.log(error)
    }
  }

  atualizarSessaoHora(index) {
    this.sessoes = this.listSession[index].sessoes;
    if (this.sessoes.length > 0) {
      this.loadAreaEvent(this.sessoes[0].codigo, this.sessoes[0]);
    }
  }

  async getDataCheckout() {
    var checkOutData = await localStorage.getItem('@DIngress:checkOutData');
    var checkOutDataCode = await localStorage.getItem('@DIngress:checkOutDataCode');
    var checkOutDataArea = await localStorage.getItem('@DIngress:checkOutDataArea');
    var checkEventCode = await localStorage.getItem('@DIngress:checkEventCode');
    const codigo = this.route.snapshot.paramMap.get('codigo');
    if (codigo == checkEventCode) {
      if (checkOutData && checkOutDataCode && checkOutDataArea) {
        this.panelHorarioToogle();
        this.panelHorarioToogleMob();
        var data = JSON.parse(checkOutData);
        try {
          if (this.listSession.length > 0) {
            var sessaoS = this.listSession[0].sessoes[0].filter(x => x.codigo == checkOutDataCode);
            if (sessaoS.length > 0) {
              this.usaMapa = sessaoS[0].usa_mapa;
              if (this.usaMapa) {
                this.iniciarSeatsIo(sessaoS[0]);
              }
            }
          }
        } catch (e) {

        }
        this.horarioSelecionado = checkOutDataCode;
        await this.getEventSessionIngress(checkOutDataCode, checkOutDataArea, data);
      } else {
        this.panelHorarioToogle();
        this.panelHorarioToogleMob();
        if (this.listSession.length > 0) {
          if (this.listSession[0].sessoes.length > 0) {
            this.horarioSelecionado = this.listSession[0].sessoes[0].codigo;
            this.usaMapa = this.listSession[0].sessoes[0].usa_mapa;
            if (this.usaMapa) {
              this.iniciarSeatsIo(this.listSession[0].sessoes[0]);
            }
            await this.getEventSessionIngress(this.listSession[0].sessoes[0].codigo, null, null);
          }
        }
      }
    } else {
      this.panelHorarioToogle();
      this.panelHorarioToogleMob();
      if (this.listSession.length > 0) {
        if (this.listSession[0].sessoes.length > 0) {
          this.horarioSelecionado = this.listSession[0].sessoes[0].codigo;
          this.usaMapa = this.listSession[0].sessoes[0].usa_mapa;
          if (this.usaMapa) {
            this.iniciarSeatsIo(this.listSession[0].sessoes[0]);
          }
          await this.getEventSessionIngress(this.listSession[0].sessoes[0].codigo, null, null);
        }
      }
    }

  }

  populateMetaTags(evento) {
    console.log("populando metatags");

    // Título da Página
    document.title = evento.nome;

    var ogImageLink = `https://${evento['imagem_banner_interno_link']}`;
    // Meta tag de descrição
    const descriptionMetaTag: any = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.content = evento.nome;
    }

    // Open Graph Meta Tags
    const ogTitleMetaTag: any = document.querySelector('meta[property="og:title"]');
    const ogDescriptionMetaTag: any = document.querySelector('meta[property="og:description"]');
    const ogSiteNameMetaTag: any = document.querySelector('meta[property="og:site_name"]');
    const ogUrlMetaTag: any = document.querySelector('meta[property="og:url"]');
    const ogImageMetaTag: any = document.querySelector('meta[property="og:image"]');
    const ogImageMetaUrlTag: any = document.querySelector('meta[property="og:image:secure_url"]');
    const ogImageWidthMetaTag: any = document.querySelector('meta[property="og:image:width"]');
    const ogImageHeightMetaTag: any = document.querySelector('meta[property="og:image:height"]');

    if (ogTitleMetaTag && ogDescriptionMetaTag && ogSiteNameMetaTag && ogUrlMetaTag && ogImageMetaTag) {
      ogTitleMetaTag.content = evento.nome;
      ogDescriptionMetaTag.content = evento.nome;
      ogSiteNameMetaTag.content = "Digital Ingressos";
      ogUrlMetaTag.content = window.location.href;
      ogImageMetaTag.content = ogImageLink;
      ogImageMetaUrlTag.content = ogImageLink;
    }

    // Twitter Meta Tags
    const twitterTitleMetaTag: any = document.querySelector('meta[name="twitter:title"]');
    const twitterDescriptionMetaTag: any = document.querySelector('meta[name="twitter:description"]');
    const twitterUrlMetaTag: any = document.querySelector('meta[name="twitter:url"]');
    const twitterCardMetaTag: any = document.querySelector('meta[name="twitter:card"]');
    const twitterImageMetaTag: any = document.querySelector('meta[name="twitter:image"]');
    const twitterImageWidthMetaTag: any = document.querySelector('meta[name="twitter:image:width"]');
    const twitterImageHeightMetaTag: any = document.querySelector('meta[name="twitter:image:height"]');

    if (twitterTitleMetaTag && twitterDescriptionMetaTag && twitterUrlMetaTag && twitterCardMetaTag && twitterImageMetaTag) {
      twitterTitleMetaTag.content = evento.nome;
      twitterDescriptionMetaTag.content = evento.nome;
      twitterUrlMetaTag.content = window.location.href;
      twitterCardMetaTag.content = "summary_large_image";
      twitterImageMetaTag.content = ogImageLink;
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }

  getAddress(address) {
    this.geoCoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.lat = results[0].geometry.location.lat();
          this.lng = results[0].geometry.location.lng();
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getEvent(code) {
    var url = "";
    if (this.codigo_pdv) {
      url = `Eventos/${code}?codigo_pdv=${this.codigo_pdv}`
    } else {
      url = `Eventos/${code}`;
    }

    this.apiService.GetPublic(url).then((res) => {
      this.event = res;
      this.populateMetaTags(res);
      // this.createMetaTags(res);
      $("#sobre_evento").html(this.event.observacao)
      $("#mais_informacao").html(this.event.mais_informacao)
      if (this.event.tipo_cupom == 'ATIVACAO' && this.cupom == null) {
        this.cupomActived = false;
      } else {
      }
      this.lat = this.event.latitude;
      this.lng = this.event.longitude;
      this.zoom = 18;
      this.title.setTitle(res['nome']);
      setTimeout(() => {
        if (this.event.tag_google_manager) {
          this.initGtag();
        }
      }, 2000);
      // this.iniciarSeatsIo();
    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `Erro ao tentar obter eventos`,
        })
      }
    });
  }


  createMetaTags(event) {
    this.meta.updateTag({ content: event['nome'] }, 'name="description"');
    this.meta.updateTag({ content: event['nome'] }, 'property="og:description"');
    this.meta.updateTag({ content: event['nome'] }, 'property="og:title"');
    this.meta.updateTag({ content: window.location.href }, 'property="og:url"');
    this.meta.updateTag({ content: `https://${event[`imagem_banner_interno_link`]}` }, 'property="og:image:secure_url"');
    this.meta.updateTag({ content: `https://${event[`imagem_banner_interno_link`]}` }, 'property="og:image"');
  }

  getEventSession(code) {
    var url = "";
    if (this.codigo_pdv) {
      url = `Eventos/${code}/Sessoes?agrupador=DATA&codigo_pdv=${this.codigo_pdv}`
    } else {
      url = `Eventos/${code}/Sessoes?agrupador=DATA`;
    }

    this.apiService.GetPublic(url).then((res) => {
      this.listSession = res;
      this.getDataCheckout();
    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `Erro ao tentar obter eventos`,
        })

      }
    });
  }

  getEventSessionIngress(code, checkOutDataArea = null, checkOutData = null) {
    var url = "";
    if (this.codigo_pdv) {
      url = `Sessoes/${code}/Ingressos?codigo_pdv=${this.codigo_pdv}`
    } else {
      url = `Sessoes/${code}/Ingressos`;
    }

    var data = Object.assign([], checkOutData);
    this.loadingArea = true;
    this.apiService.GetPublic(url).then((res) => {
      localStorage.setItem('@DIngress:checkOutDataCode', code);
      this.areaEvent = res;
      this.loadingArea = false;
      this.areaEvent.forEach(item => {
        item.ingressos.forEach(ingresso => {
          ingresso.qtd = 0; // Defina a quantidade como 0
        });
      });
      this.areaEvent.forEach(item => {
        item.ingressos.sort((a, b) => a.ordem - b.ordem);
      });
      this.valorTotal = 0;
    }).catch((err) => {
      this.loadingArea = false;
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {
      }
    });
  }

  loadAreaEvent(code, sessao) {
    this.horarioSelecionado = code;
    this.valorTotal = 0;
    this.valorTotalSemCupom = 0;

    this.usaMapa = sessao.usa_mapa;
    if (this.usaMapa) {
      this.iniciarSeatsIo(sessao);
    }
    this.getEventSessionIngress(code);
  }

  selectArea(value, event) {
    try {
      event.preventDefault();
    } catch (error) {

    }
    this.areaEvent.filter(x => x.selected = false);
    value.selected = !value.selected;
    localStorage.setItem('@DIngress:checkOutDataArea', JSON.stringify(value));

    if (value.selected) {
      this.ingressAreaSelected = value['ingressos'];
      this.ingressAreaSelected = this.ingressAreaSelected.sort(function (a, b) {
        return a.ordem - b.ordem
      });
      this.ingressAreaSelected.filter(x => x.qtd = 0);
      this.valorTotal = 0;
    } else {
      this.ingressAreaSelected = [];
      this.valorTotal = 0;
    }
  }

  panelAboutToogle() {
    this.sectionAbout.nativeElement.classList.toggle('sectionClose');
    if (!this.sectioLocalization.nativeElement.classList.contains('sectionClose')) {
      this.sectioLocalization.nativeElement.classList.add('sectionClose');
    }

    if (!this.sectionInfo.nativeElement.classList.contains('sectionClose')) {
      this.sectionInfo.nativeElement.classList.add('sectionClose');
    }
  }

  panelLocalizationToogle() {
    this.sectioLocalization.nativeElement.classList.toggle('sectionClose');
    this.exibirMapa = true;

    if (!this.sectionAbout.nativeElement.classList.contains('sectionClose')) {
      this.sectionAbout.nativeElement.classList.add('sectionClose');
    }

    if (!this.sectionInfo.nativeElement.classList.contains('sectionClose')) {
      this.sectionInfo.nativeElement.classList.add('sectionClose');
    }

  }

  panelInfoToogle() {
    this.sectionInfo.nativeElement.classList.toggle('sectionClose');

    if (!this.sectioLocalization.nativeElement.classList.contains('sectionClose')) {
      this.sectioLocalization.nativeElement.classList.add('sectionClose');
    }

    if (!this.sectionAbout.nativeElement.classList.contains('sectionClose')) {
      this.sectionAbout.nativeElement.classList.add('sectionClose');
    }
  }

  panelHorarioToogle() {
    this.sectionHorario.nativeElement.classList.toggle('horarioSecaoClose');
  }

  panelHorarioToogleMob() {
    this.sectionHorarioMob.nativeElement.classList.toggle('horarioSecaoClose');
  }

  formatDateDay(value) {
    if (value) {
      return moment(value).format("DD");
    }
    return "--";
  }

  formateDateMonth(value) {
    var meses = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro "];
    if (value) {
      var monthIndex = moment(value).month();
      return meses[monthIndex];
    }
    return "--";
  }

  formateDateDayWeek(value) {
    var week = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
    if (value) {
      var weekIndex = moment(value).day();
      return week[weekIndex].substring(0, 3);
    }
    return "--";
  }

  formateTime(value) {
    if (value) {
      return moment(value).format("HH:mm");
    }
    return "--";
  }

  formaterCurrency(value) {
    if (value) {
      if (value.includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value
  }

  addIngress(value, event) {

    event.preventDefault();
    value.qtd = parseInt(value.qtd) + 1;
    if (value.qtd > value.quantidade) {
      value.qtd = parseInt(value.qtd) - 1;
      this.alerts.alertError({
        title: 'Atenção',
        text: `Valor máximo de ingresso`,
      })
      return
    }
    if (this.cupom) {
      if (this.cupom.quantidade_ingresso) {
        var somaQtd = 0;
        const ingressosComQuantidadeMaiorQueZero = [];

        for (const item of this.areaEvent) {
          for (const ingresso of item.ingressos) {
            if (ingresso.qtd > 0) {
              ingressosComQuantidadeMaiorQueZero.push(ingresso);
            }
          }
        }

        for (let index = 0; index < ingressosComQuantidadeMaiorQueZero.map(function (a) { return a.qtd; }).length; index++) {
          somaQtd = somaQtd + ingressosComQuantidadeMaiorQueZero.map(function (a) { return a.qtd; })[index];
        }

        if (somaQtd > this.cupom.quantidade_ingresso) {
          value.qtd = parseInt(value.qtd) - 1;
          this.alerts.alertError({
            title: 'Atenção',
            text: `Valor máximo de ingresso`,
          })
          return
        }
      }
      if (this.cupom.desconto > 0 && this.cupom.tipo_desconto != "PORCENTAGEM") {

        // if (this.valorTotal == 0) {
        //   this.valorTotal = this.valorTotal - this.cupom.desconto;
        // }
        // this.valorTotal = ((value.valor * value.qtd) + this.valorTotal) - this.cupom.desconto;

        // if (this.valorTotal < 0) {
        //   this.valorTotal = 0;
        // }

        if (this.valorTotalSemCupom == 0) {
          this.valorTotalSemCupom = this.valorTotalSemCupom;
        }

        this.valorTotalSemCupom = value.valor + this.valorTotalSemCupom;

        var valorSubitraido: any = this.valorTotalSemCupom - this.cupom.desconto;

        this.valorTotal = parseFloat(valorSubitraido).toFixed(2);
        if (this.valorTotal < 0) {
          this.valorTotal = 0;
        }

      } else if (this.cupom.tipo_desconto == "PORCENTAGEM" && this.cupom.desconto > 0) {
        this.valorTotal = this.valorTotal + (((value.preco_ingresso) * (100 - this.cupom.desconto)) / 100) + value.taxa_servico;
      }
    } else {
      this.valorTotal = value.valor + this.valorTotal;
    }
  }

  removeIngress(value, event) {
    event.preventDefault();

    if (value.qtd > 0) {
      value.qtd = parseInt(value.qtd) - 1;
      if (this.cupom) {

        if (this.cupom.desconto > 0 && this.cupom.tipo_desconto != "PORCENTAGEM") {
          const ingressosComQuantidadeMaiorQueZero = [];

          for (const item of this.areaEvent) {
            for (const ingresso of item.ingressos) {
              if (ingresso.qtd > 0) {
                ingressosComQuantidadeMaiorQueZero.push(ingresso);
              }
            }
          }

          this.valorTotal = this.valorTotal - value.valor;

          if (value.qtd == 0 && ingressosComQuantidadeMaiorQueZero.length == 0) {
            this.valorTotal = this.valorTotal + this.cupom.desconto;
          }


          if (this.valorTotal < 0) {
            this.valorTotal = 0;
          }
          if (ingressosComQuantidadeMaiorQueZero.length == 0) {
            this.valorTotal = 0;
            this.valorTotalSemCupom = 0;
          } else {
            this.valorTotalSemCupom = this.valorTotalSemCupom - value.valor;
          }
          // this.valorTotal = this.valorTotal + this.cupom.desconto;
        } else if (this.cupom.tipo_desconto == "PORCENTAGEM" && this.cupom.desconto > 0) {
          this.valorTotal = this.valorTotal - ((((value.preco_ingresso) * (100 - this.cupom.desconto)) / 100) + value.taxa_servico);
        }
      } else {
        this.valorTotal = this.valorTotal - value.valor;
      }
    }
  }


  formatarValorCupomPorcentagem(valor) {
    if (this.cupom) {
      var valorTotal = 0;
      valorTotal = valorTotal + (((valor) * (100 - this.cupom.desconto)) / 100);
      return valorTotal;
    } else {
      return valor;
    }
  }

  async goToPaymant() {
    var data;
    var listaCheckout = [];

    // data = this.ingressAreaSelected.filter(x => x.qtd > 0);

    const ingressosComQuantidadeMaiorQueZero = [];

    for (const item of this.areaEvent) {
      for (const ingresso of item.ingressos) {
        if (ingresso.qtd > 0) {
          ingressosComQuantidadeMaiorQueZero.push(ingresso);
        }
      }
    }

    data = ingressosComQuantidadeMaiorQueZero;
    await localStorage.setItem('@DIngress:checkOutData', JSON.stringify(data));

    //salvar codigo
    const codigo = this.route.snapshot.paramMap.get('codigo');
    await localStorage.setItem('@DIngress:checkEventCode', codigo);

    data.forEach(element => {
      var obj = {};
      if (this.codigo_pdv) {
        obj =
        {
          uuid_valor: element.valor_uuid,
          quantidade: element.qtd,
          pdv_codigo: this.codigo_pdv,
          cupom_codigo: this.cupom ? this.cupom.codigo : ""

        }
      } else {
        obj =
        {
          uuid_valor: element.valor_uuid,
          quantidade: element.qtd,
          cupom_codigo: this.cupom ? this.cupom.codigo : ""
        }
      }
      obj['combo'] = element.combo;
      listaCheckout.push(obj);
    });


    listaCheckout.forEach(item => {
      item.lista_posses = [];
      var dado = data.filter(x => x.valor_uuid == item.uuid_valor)[0];
      for (var index = 0; index < item.quantidade; index++) {
        var obj = {
          "ingresso": Math.floor(Math.random() * 100000000).toString() + dado.valor_uuid,
          "nome_ingresso": dado.nome,
          "valor_uuid": dado.valor_uuid,
          "comprador_posse": false,
          "combo": item.combo,
          "tipo": dado.tipo
        }

        if (item.combo > 1) {
          for (let combo = 0; combo < item.combo; combo++) {
            var obj_ = Object.assign({}, obj);
            obj_.ingresso = Math.floor(Math.random() * 100000000).toString() + dado.valor_uuid;
            item.lista_posses.push(obj_);
          }
        } else {
          item.lista_posses.push(obj);
        }
      }
    });



    if (listaCheckout.length > 0) {
      this.checkClientLogin(listaCheckout);
    }
  }

  checkOutData_(data) {
    if (this.loadingCheckout) {
      return;
    }

    this.loadingCheckout = true;
    this.apiService.Post(`Checkout`, data).then((res) => {
      this.loadingCheckout = false;
      // this.authService.setPayment(res);
      res['evento'] = this.event;
      localStorage.setItem('@DIngress:checkOut', JSON.stringify(res));
      if (this.codigo_pdv) {

        this.router.navigate([`/payment/${this.codigo_pdv}`]);

      } else {
        this.router.navigate(['/payment']);
      }

      this.authService.saveCurrentCheckout(null);
      localStorage.setItem('@DIngress:checkOutData', "");
      localStorage.setItem('@DIngress:checkOutDataCode', "");
      localStorage.setItem('@DIngress:checkOutDataArea', "");
    }).catch((err) => {
      this.loadingCheckout = false;
      if (err.status == 401) {
        this.checkClientLogin(data);
      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `${err.error}`,
        })
      }
    });
  }

  getUserCliente(checkout = false) {
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];
    }).catch((err) => {
      if (err.status == 401) {

      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `${err.error}`,
        })
      }
    });
  }

  checkClientLogin(listaCheckout: any) {
    if (this.loadingCheckout) {
      return;
    }

    this.loadingCheckout = true;
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];
      this.ingressoPosse(listaCheckout);
      this.loadingCheckout = false;
    }).catch((err) => {
      if (err.status == 401) {
        this.loginUser(listaCheckout);
        this.loadingCheckout = false;
      }
    });
  }

  checkIngressoSelected(ingressAreaSelected) {

    var existeIngresso = this.temIngressoComQuantidadeMaiorQueZero(this.areaEvent);
    return existeIngresso;
  }

  temIngressoComQuantidadeMaiorQueZero(dados) {
    for (const item of dados) {
      for (const ingresso of item.ingressos) {
        if (ingresso.qtd > 0) {
          return true; // Existe pelo menos 1 ingresso com quantidade maior que 0
        }
      }
    }
    return false; // Não há nenhum ingresso com quantidade maior que 0
  }

  loginUser(listaCheckout: any) {
    const dialogRef = this.dialog.open(ModalLoginCadastroComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        this.checkClientLogin(listaCheckout);
      }
    });
  }

  ingressoPosse(listaCheckout: any) {
    const dialogRef = this.dialog.open(ModalIngressoPosseComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: {
        listaCheckout: listaCheckout
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.getTimeServidor(listaCheckout);
      }
    });
  }
  getTimeServidor(listaCheckout) {
    this.apiService.GetV2('DataHora').then(async (res) => {
      var hora: any = res;
      localStorage.setItem('@DIngress:listaCheck', JSON.stringify(listaCheckout));
      localStorage.setItem('@DIngress:listaCheckTime', moment(hora).toString());
      this.checkOutData_(listaCheckout);
    }).catch((err) => {
      console.log(err)
    });
  }

  checkCupom(cupom: any) {
    if (this.loadingCupom) {
      return;
    }

    if (cupom == null || cupom == "") {
      return;
    }
    this.loadingCupom = true;
    this.valorTotal = 0;
    this.apiService.GetPublic(`Eventos/${this.event.codigo}/Cupom/${cupom.toUpperCase()}`).then((res) => {
      this.cupomActived = true;
      this.cupom = res;
      for (const item of this.areaEvent) {
        for (const ingresso of item.ingressos) {
          ingresso.qtd = 0;
        }
      }
      this.valorTotalSemCupom = 0;
      this.loadingCupom = false;

      if (this.usaMapa) {
        for (let index = 0; index < this.selectedSeatsArray.length; index++) {
          const element = this.selectedSeatsArray[index];
          if (element.ingresso) {
            element.ingresso = null;
          }
        }
      }

      this.initScreen();
      setTimeout(() => {
        this.swiperDay.update()
      }, 2000);

    }).catch((err) => {
      this.loadingCupom = false;
      this.alerts.alertError({
        title: 'Atenção',
        text: `${err.error}`,
      })
    });
  }

  checkLink(link: any) {
    if (!link) {
      return false;
    }
    if (link.split('/').length > 1) {
      var split = link.split('/');
      if (split[1].length > 0) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  //SEATS IO INGRESSO

  iniciarSeatsIo(sessao) {
    let that = this;

    const chartDiv = document.getElementById('chart');
    if (chartDiv) {
      chartDiv.innerHTML = ''; // Apaga todo o conteúdo da div
    }
    if (this.seatingChart) {
      this.selectedSeatsArray = [];
      // this.seatingChart.reset();
      this.seatingChart.destroy();
    }

    this.sessaoSelecionadaMapa = sessao;
    this.seatingChart = new seatsio.SeatingChart({
      divId: 'chart',
      workspaceKey: sessao.workspace_key,
      event: sessao.event_key,
      session: 'continue',
      onChartRendered: function (chart) {
      },
      onChartRenderingFailed: function (chart) {
        chart.destroy();
      },
      priceFormatter: function (price) {
        return 'R$' + price;
      },
      onObjectClicked: function (object) {
      },
      onObjectSelected: function (object) {
        that.selectedSeatsArray.push(object);
      },
      onObjectDeselected: function (object) {
        that.selectedSeatsArray = that.selectedSeatsArray.filter(seat => seat.id !== object.id);
      },
      onObjectStatusChanged: function (object) {
      },
      onSelectedObjectBooked: function (object) {
      },
    }).render();
  }

  modalSelecionarIngresso(seatSelecionado: any) {
    const dialogRef = this.dialog.open(ModalIngressoSeatsComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        seatSelecionado: seatSelecionado,
        sessaoSelecionada: this.sessaoSelecionadaMapa,
        cupom: this.cupom
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        seatSelecionado.ingresso = result;
        seatSelecionado.ingresso.qtd = 1;
        seatSelecionado.ingresso.seat = seatSelecionado.label;
        seatSelecionado.ingresso.holdToken = seatSelecionado.chart.holdToken
        this.valorTotal = 0;
        this.valorTotalSemCupom = 0;
        for (let index = 0; index < this.selectedSeatsArray.length; index++) {
          const element = this.selectedSeatsArray[index];
          if (element.ingresso) {
            this.inserirIngressoValor(element.ingresso);
          }
        }
      }
    });
  }

  inserirIngressoValor(value) {
    // value.qtd = parseInt(value.qtd) + 1;
    if (value.qtd > value.quantidade) {
      value.qtd = parseInt(value.qtd) - 1;
      this.alerts.alertError({
        title: 'Atenção',
        text: `Valor máximo de ingresso`,
      })
      return
    }
    if (this.cupom) {
      if (this.cupom.quantidade_ingresso) {
        var somaQtd = 0;
        const ingressosComQuantidadeMaiorQueZero = [];

        for (const item of this.areaEvent) {
          for (const ingresso of item.ingressos) {
            if (ingresso.qtd > 0) {
              ingressosComQuantidadeMaiorQueZero.push(ingresso);
            }
          }
        }

        for (let index = 0; index < ingressosComQuantidadeMaiorQueZero.map(function (a) { return a.qtd; }).length; index++) {
          somaQtd = somaQtd + ingressosComQuantidadeMaiorQueZero.map(function (a) { return a.qtd; })[index];
        }
        if (somaQtd > this.cupom.quantidade_ingresso) {
          value.qtd = parseInt(value.qtd) - 1;
          this.alerts.alertError({
            title: 'Atenção',
            text: `Valor máximo de ingresso`,
          })
          return
        }
      }
    }
    if (this.cupom.desconto > 0 && this.cupom.tipo_desconto != "PORCENTAGEM") {
      if (this.valorTotalSemCupom == 0) {
        this.valorTotalSemCupom = this.valorTotalSemCupom;
      }

      this.valorTotalSemCupom = value.valor + this.valorTotalSemCupom;

      var valorSubitraido: any = this.valorTotalSemCupom - this.cupom.desconto;

      this.valorTotal = parseFloat(valorSubitraido).toFixed(2);
      if (this.valorTotal < 0) {
        this.valorTotal = 0;
      }

    } else if (this.cupom.tipo_desconto == "PORCENTAGEM" && this.cupom.desconto > 0) {
      this.valorTotal = this.valorTotal + (((value.preco_ingresso) * (100 - this.cupom.desconto)) / 100) + value.taxa_servico;
    }
  }


  assentosComValor(arrayAssentos: any = []) {
    if (arrayAssentos.length > 0) {
      var verif = arrayAssentos.filter(x => x.ingresso == null);
      if (verif.length > 0) {
        return false
      } else {
        return true;
      }
    } else {
      return false;
    }
  }


  async goToPaymantSeats() {

    var data;
    var listaCheckout = [];


    data = this.selectedSeatsArray.map(item => item.ingresso).reduce((acc, curr) => {
      const existing = acc.find(item => item.codigo === curr.codigo);
      if (existing) {
        if (!existing.seats) {
          existing.seats = [];
          existing.seats.push(existing.seat);
        }
        existing.qtd += curr.qtd; // Soma a quantidade
        existing.seats.push(curr.seat); // Soma a quantidade
      } else {
        curr.seats = [];
        curr.seats.push(curr.seat);
        acc.push({ ...curr }); // Adiciona o item se não existir
      }
      return acc;
    }, []);

    // data = ingressosComQuantidadeMaiorQueZero;
    await localStorage.setItem('@DIngress:checkOutData', JSON.stringify(data));
    //salvar codigo
    const codigo = this.route.snapshot.paramMap.get('codigo');
    await localStorage.setItem('@DIngress:checkEventCode', codigo);

    data.forEach(element => {
      var obj = {};
      if (this.codigo_pdv) {
        obj =
        {
          uuid_valor: element.valor_uuid,
          quantidade: element.qtd,
          pdv_codigo: this.codigo_pdv,
          seat_event_key: this.sessaoSelecionadaMapa.event_key,
          seat_hold_token: element.holdToken,
          cupom_codigo: this.cupom ? this.cupom.codigo : "",
          seats: element.seats ? element.seats : [element.seat]
        }
      } else {
        obj =
        {
          uuid_valor: element.valor_uuid,
          quantidade: element.qtd,
          seat_event_key: this.sessaoSelecionadaMapa.event_key,
          seat_hold_token: element.holdToken,
          cupom_codigo: this.cupom ? this.cupom.codigo : "",
          seats: element.seats ? element.seats : [element.seat]
        }
      }
      obj['combo'] = element.combo;
      listaCheckout.push(obj);
    });

    listaCheckout.forEach(item => {
      item.lista_posses = [];
      var dado = data.filter(x => x.valor_uuid == item.uuid_valor)[0];
      for (var index = 0; index < item.quantidade; index++) {
        var obj = {
          "ingresso": Math.floor(Math.random() * 100000000).toString() + dado.valor_uuid,
          "nome_ingresso": dado.nome,
          "valor_uuid": dado.valor_uuid,
          "comprador_posse": false,
          "combo": item.combo,
          "tipo": dado.tipo,
          "seat_object": item.seats[index]
        }

        if (item.combo > 1) {
          for (let combo = 0; combo < item.combo; combo++) {
            var obj_ = Object.assign({}, obj);
            obj_.ingresso = Math.floor(Math.random() * 100000000).toString() + dado.valor_uuid;
            item.lista_posses.push(obj_);
          }
        } else {
          item.lista_posses.push(obj);
        }
      }
    });
    if (listaCheckout.length > 0) {
      this.checkClientLogin(listaCheckout);
    }
  }

}
